import { defineStore } from 'pinia';


export const GlobalInfo = defineStore('globalInfo', {
    state: (): any => ({
        loading: false,
    }),
    actions: {
        // 设置用户信息
        async setLoading(loading: Boolean) {
            this.loading = loading;
        },
    },
});

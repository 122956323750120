import request from '/@/utils/request';

/**
 * （不建议写成 request.post(xxx)，因为这样 post 时，无法 params 与 data 同时传参）
 *
 * 用户相关api接口集合
 * @method getCurrentAccount 	根据token获取当前用户信息
 * @method getAccountRoleList 	获取人员角色类型列表
 * @method addAccountRole		新增一条人员角色类型信息
 * @method deleteAccountRole	删除一条人员角色类型信息
 * @method updateAccountRole	更新一条人员角色类型信息
 * @method getAccountRoleByID	根据id获取一条人员角色类型信息
 * @method changePassword	    修改密码
 * @method updateAccount	    更新账户信息
 */
export function useAccountApi() {
	return {
		// role 相关
		getCurrentAccount: () => {
			return request({
				url: '/account/current_account/',
				method: 'get',
			});
		},
		getAccountRoleList: () => {
			return request({
				url: '/account/account_role/',
				method: 'get',
			});
		},
		addAccountRole: (data: RowAccountRoleType) => {
			return request({
				url: '/account/account_role/',
				method: 'post',
				data,
			});
		},
		deleteAccountRole: (data: RowAccountRoleType) => {
			return request({
				url: `/account/account_role/${data.id}/`,
				method: 'delete',
				data,
			});
		},
		deleteAccountRoleAndChildren: (data: RowAccountRoleType) => {
			return request({
				url: `/account/account_role/delete_role_and_children/${data.id}/`,
				method: 'get',
				data,
			});
		},
		updateAccountRole: (data: RowAccountRoleType) => {
			return request({
				url: `/account/account_role/${data.id}/`,
				method: 'put',
				data,
			});
		},

		getAccountRoleById: (params?: object) => {
			return request({
				url: '/account/account_role/',
				method: 'get',
				params,
			});
		},

		getAllAccount: (params?: object) => {
			return request({
				url: '/account/all_accounts/',
				method: 'get',
				params,
			});
		},

		addAccount: (data: RowAccountUserType) => {
			return request({
				url: '/account/create_new_account_view/',
				method: 'post',
				data,
			});
		},

		search: (params: any) => {
			return request({
				url: '/account/search_accounts/',
				method: 'get',
				params,
			});
		},
		
		deleteAccount: (data: RowAccountUserType) => {
			return request({
				url: `/account/delete_account/${data.id}/`,
				method: 'get',
			});
		},

		getAccountByRoleId: (params?: any) => {
			return request({
				url: `/account/search_accounts_by_role/${params.id}/`,
				method: 'get',
			});
		},

		// user相关
		getAccount: (data?: object) => {
			return request({
				url: '/account/current_account',
				method: 'get',
				data,
			});
		},

		// 修改密码
		changePassword: (data: changePasswodData) => {
			return request({
				url: '/account/change_password/',
				method: 'post',
				data,
			});
		},
		// 编辑账户
		updateAccount: (data: basicFormData) => {
			return request({
				url: '/account/edit_account_view/',
				method: 'post',
				data,
			});
		},

		// 修改头像
		uploadAccountAvatar: (data: any) => {
			return request({
				url: '/account/update_account_avatar/',
				method: 'post',
				data,
			});
		},
	};
}

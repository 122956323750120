import { defineStore } from 'pinia';
import Cookies from 'js-cookie';
import { Local } from '/@/utils/storage';
import { useLoginApi } from '/@/api/login/index';
import { useAccountApi } from '/@/api/account/index';
import other from '/@/utils/other'

// 引入 api 请求接口
const loginApi = useLoginApi();
const accountApi = useAccountApi();

const getUserInfos = () => {
	const userInfos: any = Local.get('userInfo');
	return userInfos ? userInfos : {
		username: '',
		nickname: '',
		avatar_url: '',
		time: 0,
		phone_number: '',
		roles: [],
		is_superuser: false,
		menu_permission: [],
		authBtnList: [],
		initial_password_updated: false,
		sex: '',
	}
}

/**
 * 用户信息
 * @methods setUserInfos 设置用户信息
 */
export const useUserInfo = defineStore('userInfo', {
	state: (): UserInfosState => ({
		userInfos: getUserInfos(),
	}),
	actions: {
		// 设置用户信息
		async setUserInfos() {
			// 存储用户信息到浏览器缓存
			if (Local.get('userInfo')) {
				this.userInfos = Local.get('userInfo');
			} else {
				const userInfos: any = await this.getApiUserInfo();
				this.userInfos = userInfos;
			}
		},
		// 调接口获取用户信息
		async getApiUserInfo() {
			try {
				const res = await accountApi.getCurrentAccount();
			} catch (error) {}
		},
		// 用户登录
		async signIn(userInfo: any) {
			let result = true;
			try {
				const { data, success } = await loginApi.signIn(userInfo);
				if (success && data) {
					const { account, token } = data;
					// 有token
					if (token) {
						// 存储 token 到浏览器缓存
						Local.set('token', token);
					}
					// 有账户信息
					if (account) {
						const { username, avatar_url, nickname, is_superuser, phone_number, role, initial_password_updated, sex } = account;
						let menu_permission = ['admin'];
						if (role?.menu_permission) {
							menu_permission = role.menu_permission;
						}
						let defaultRoles: Array<string> = [];
						let defaultAuthBtnList: Array<string> = [];
						// admin 页面权限标识，对应路由 meta.roles，用于控制路由的显示/隐藏
						let adminRoles: Array<string> = ['admin'];
						// admin 按钮权限标识
						let adminAuthBtnList: Array<string> = ['btn.add', 'btn.del', 'btn.edit', 'btn.link'];
						// test 页面权限标识，对应路由 meta.roles，用于控制路由的显示/隐藏
						let testRoles: Array<string> = ['common'];
						// test 按钮权限标识
						let testAuthBtnList: Array<string> = ['btn.add', 'btn.link'];
						// 超级管理员
						if (is_superuser) {
							defaultRoles = adminRoles;
							defaultAuthBtnList = adminAuthBtnList;
						} else {
							defaultRoles = testRoles;
							defaultAuthBtnList = testAuthBtnList;
						}
						const userInfos: any = {
							username,
							nickname,
							avatar_url,
							time: new Date().getTime(),
							roles: defaultRoles,
							is_superuser,
							menu_permission,
							authBtnList: defaultAuthBtnList,
							phone_number,
							initial_password_updated,
							sex,
						};
						this.userInfos = userInfos;
						Local.set('userInfo', userInfos);
					}
				} else {
					result = false;
				}
			} catch (error) {
				result = false;
			} finally {
				return result;
			}
		},
		// 用户登录
		async signOut() {
			try {
				const res = await loginApi.signOut();
			} catch (error) {
				console.log(error);
			} finally {
			}
		},
		// 设置人员角色类型树形数据
		async getAcoountRoleList() {
			let result: any = [];
			try {
				const { data } = await accountApi.getAccountRoleList();
				if (data?.length) {
					result = other.array2Tree(data);
				}
			} catch (error) {
				console.log(error);
			} finally {
				return result;
			}
		},
	},
});

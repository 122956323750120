import axios, { AxiosInstance } from 'axios';
import { ElMessage, ElMessageBox } from 'element-plus';
import { Local, Session } from '/@/utils/storage';
import qs from 'qs';

const notAuth: string[] = ['/account/login/'];

// 配置新建一个 axios 实例
const service: AxiosInstance = axios.create({
	baseURL: '/api/v1',
	timeout: 50000,
	headers: { 'Content-Type': 'application/json' },
	paramsSerializer: {
		serialize(params) {
			return qs.stringify(params, { allowDots: true });
		},
	},
});

// 添加请求拦截器
service.interceptors.request.use(
	(config) => {
		console.log(333, config);
		if (notAuth.findIndex((d) => d == config.url) == -1) {
			// 在发送请求之前做些什么 token
			if (Local.get('token')) {
				config.headers!['Authorization'] = `token ${Local.get('token')}`;
			}
		}
		return config;
	},
	(error) => {
		// 对请求错误做些什么
		return Promise.reject(error);
	}
);

// 添加响应拦截器
service.interceptors.response.use(
	(response) => {
		// 对响应数据做点什么
		const res = response.data;
		const code = response.status;
		console.log(22222);
		if ((res?.code != 200 && res?.code != 201) && code != 200 && code != 201) {
			// `token` 过期或者账号已在别处登录
			if (res.code === 401 || res.code === 4001) {
				Session.clear(); // 清除浏览器全部临时缓存
				Local.clear();
				window.location.href = '/'; // 去登录页
				ElMessageBox.alert('你已被登出，请重新登录', '提示', {})
					.then(() => {})
					.catch(() => {});
			}
			return Promise.reject(service.interceptors.response);
		} else {
			return response.data || true;
		}
	},
	(error) => {
		// 对响应错误做点什么
		const { response } = error;
		console.log(111111);
		const { data } = response;
		if (error.message.indexOf('timeout') != -1) {
			ElMessage.error('网络超时');
		} else if (error.message == 'Network Error') {
			ElMessage.error('网络连接错误');
		} else {
			if (error.response.data) ElMessage.error(error.response.statusText);
			else ElMessage.error('接口路径找不到');
		}
		if (data?.code != 200 && data?.code != 201) {
			// `token` 过期或者账号已在别处登录
			if (data.code === 401 || data.code === 4001) {
				Session.clear(); // 清除浏览器全部临时缓存
				Local.clear();
				window.location.href = '/'; // 去登录页
				ElMessageBox.alert('你已被登出，请重新登录', '提示', {})
					.then(() => {})
					.catch(() => {});
			}
		}
		return Promise.reject(error);
	}
);

// 导出 axios 实例
export default service;
